import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SEO } from "wmk-lib";
import Layout from "../components/layout/MainLayout";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="Error 404 Not Found"
      baseUrl={baseUrl}
      twitterHandle={twitter}
      siteTitle={siteTitle}
      slug="404"
      path=""
    />
    <Container>
      <Row>
        <Col style={{ padding: `6vh`, textAlign: "center" }}>
          <h1>404 NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist.</p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default NotFoundPage;
